<template>
    <div class="advPicture">
        <van-overlay :show="show" v-if="advList && advList.length > 0">
            <div class="vipContent">
                <div>
                    <div class="bgImg">
                        <div  class="openBtn" @click="goAdv(advList[advList.length - 1])">
                            <ImgDecypt :src="advList[advList.length - 1].cover" :key="advList[advList.length - 1].cover" />
                        </div>
                    </div>
                    <div class="closeBtn" @click="close">
                        <img src="@/assets/png/vipPopBtn.png">
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt";
import {getSessionItem, setSessionItem} from "@/utils/longStorage";

export default {
    name: "AdvPicture",
    components:{
        ImgDecypt
    },
    props:{
        show: {
            type: Boolean,
            default: false
        },
        // advList:{
        //     type: Array,
        //     default: () =>{
        //         return [];
        //     }
        // }
    },
    // watch: {
    //     advList() {

    //     }
    // },
    data() {
        return {
            advList: [],
            // advInfo: {},
        }
    },
    created() {
        this.advList = getAdItem(AdType.FREE_VIDEO).reverse();
        // console.log(this.advList)
        // if(advList.length > 0 ){
        //     this.advList = advList
        //     console.log(this.advList)
        //     // if (getSessionItem('advPicturePop') != '1') {
        //     //     setSessionItem('advPicturePop', '1')
        //     //     this.advPictureShow = true;
        //     // }
        // }
    },methods:{
        goAdv(item) {
            jumpAdv(item);
        },close(){
            this.advList.pop();
            if (this.advList.length <= 0) {
                this.$emit("close",this.show);
            }
            // this.$emit("close",this.show);
        }
    }
}
</script>

<style lang="scss" scoped>
.advPicture{

    /deep/ .van-overlay{
        z-index: 29;
        background-color:rgba(0,0,0,0.6)
    }

    .vipContent{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .bgImg {
            width: 280px;
            height: 373.34px;
        }
        
        
        .openBtn{
            position: absolute;
            
            width: 280px;
            height: 373.34px;
            //padding-top: 288.2px;
            display: flex;
            justify-content: center;

            img{
                width: 148px;
                height: 47px;
            }
        }

        .closeBtn{
            display: flex;
            justify-content: center;
            margin-top: 44px;

            img{
                width: 31px;
                height: 31px;
            }
        }
    }
}
</style>