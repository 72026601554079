<template>
  <div class="advSudoku">
    <van-overlay v-if="advList.length > 0" :show="show">
      <div class="advSudokuContent">
        <div>
          <!-- <AdvSwiper
            :advList="swiperAdvs"
            v-if="swiperAdvs.length"
            :hasPagination="swiperAdvs.length > 1 ? true : false"
            class="advSwiper"
          /> -->
          <div class="advList" @touchstart.stop @touchmove.stop @touchend.stop>
            <div
              class="advItem"
              v-for="(item, index) in advList.slice(0, 16)"
              :key="index"
              @click="goAdv(item)"
            >
              <div class="icon">
                <ImgDecypt
                  :key="item['officialImg']"
                  :src="item['officialImg']"
                />
              </div>
              <div class="title ellipsis">
                <span>{{ item.officialName }}</span>
              </div>
            </div>
          </div>
          <div class="closeBtn" @click="close">
            <img src="@/assets/png/vipPopBtn.png" />
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import { jumpAdv } from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt";
import AdvSwiper from "@/components/AdvSwiper";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
// import {getSessionItem, setSessionItem} from "@/utils/longStorage";

export default {
  name: "AdvSudoku",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    advList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    ImgDecypt,
    // AdvSwiper,
  },
  data() {
    return {
      swiperAdvs: [],
    };
  },
  mounted() {
    console.log(this.advList);
    this.swiperAdvs = getAdItem(AdType.NINEGRID_SWIPER);
    // this.advList = getOfficialConfigApps();
    // if (this.advList.length > 0 && getSessionItem('advSudokuPop') != '1') {
    //     setSessionItem('advSudokuPop', '1')
    // }
  },
  methods: {
    goAdv(item) {
      jumpAdv(item);
    },
    close() {
      this.$emit("close", this.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.advSudoku {
  /deep/ .van-overlay {
    z-index: 34;
    background: rgba(0, 0, 0, 0.8);
  }
  .advSwiper {
    width: 329px;
    height: 88px;
    margin: 0 auto;
    /deep/ img {
      height: 88px;
    }
  }

  .advSudokuContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .advList {
      display: grid;
      // grid-template-columns: 81px 81px 81px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 20px;
      // grid-gap: 21px;
      grid-column-gap: 12px;
      grid-row-gap: 18px;
      padding: 0 32px;
      box-sizing: border-box;
      max-height: 426px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .advItem {
        .icon {
          width: 68px;
          height: 68px;
          border-radius: 12px;
          background: rgba(27, 22, 76, 0);
          overflow: hidden;

          // /deep/ .van-image{
          //     border-radius: 3px;
          // }

          // /deep/ .van-image__img{
          //     border-radius: 3px;
          // }
        }

        .title {
          color: rgb(255, 255, 255);
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          margin-top: 8px;
        }

        .details {
          font-size: 8px;
          color: rgb(69, 231, 222);
          text-align: center;
          margin-top: 1px;
        }

        .downloadBtn {
          display: flex;
          justify-content: center;
          margin-top: 14px;

          img {
            width: 72px;
            height: 17px;
          }
        }
      }
    }

    .closeBtn {
      margin-top: 18px;
      display: flex;
      justify-content: center;

      img {
        width: 31px;
        height: 31px;
      }
    }
  }
}
</style>
