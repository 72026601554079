<template>
  <div class="announcement_warp">
    <van-overlay :show="show" :lock-scroll="false">
      <div class="announcement_box">
        <div class="announcementTitle">官方公告</div>
        <div class="content">
          <div v-for="item in advList" :key="item.id">
            {{ item.content }}
          </div>
        </div>
        <div class="btn" @click="close">我知道了</div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import {Overlay} from "vant";
import {getAnnouncementText} from "@/utils/getConfig"
import {getSessionItem, setSessionItem} from "@/utils/longStorage";

export default {
    props:{
        show: {
            type: Boolean,
            default: false
        },
        advList:{
            type: Array,
            default: () =>{
                return [];
            }
        }
    },
  components: {
    [Overlay.name]: Overlay,
  },
  created() {
    // this.list = getAnnouncementText();
    //
    //   if (this.list.length > 0 && getSessionItem('announcementTextPop') != '1') {
    //       setSessionItem('announcementTextPop', '1')
    //       this.show = true;
    //   }
    // if (this.list.length > 0 && getSessionItem('firstTime') != '1') {
    //   setSessionItem('firstTime', '1')
    //   this.show = true;
    // }
  },
  methods: {
    close() {
        this.$emit("close",this.show);
      // this.list.pop()
      // if (this.list.length == 0) {
      //   this.show = false;
      // }
      //   this.show = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.announcement_warp {
    /deep/ .van-overlay{
        z-index: 33;
    }

  .announcement_box {
    width: 335px;
    height: 396px;
    // background-image: url("../../assets/png/announcement_bg.png");
    // background-color: rgb(230,230,230);
    // background-size: 100%;
    // background-repeat: no-repeat;
   background: linear-gradient(180deg, #FFFCE0 0%, #FFFFFF 30%);
   position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
      border-radius: 10px;
      color: #333333;

      .announcementTitle{
          font-size: 24px;
          color: rgba(51, 51, 51, 1);
          text-align: center;
          margin-top: 30px;
          font-weight: 500;
          //padding-bottom: 18px;
          //border-bottom: solid 1px rgb(230,230,230);
      }

    .content {
      margin-top: 18px;
      font-size: 12px;
      padding-left: 20px;
      padding-right: 20px;
      //padding-bottom: 20px;
      line-height: 20px;
      height: 295px;
      color: rgba(51, 51, 51, 1);
      overflow-y: auto;
      white-space: pre-wrap;
      -webkit-overflow-scrolling: touch;
    }

      .content::-webkit-scrollbar {
          display: block;
          width: 2px;
          height: 10px;
          border-radius: 2px;
      }
      .content::-webkit-scrollbar-thumb {
          border-radius: 2px;
          // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          border-radius: 10px;
          background: rgba(251, 209, 61, 1);
          //background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.44,rgb(60,186,146)),color-stop(0.72,rgb(253,187,45)),color-stop(0.86,rgb(253,187,45)));
          transition: 0.3s ease-in-out;
      }
      // .content::-webkit-scrollbar-track {
      //     border-radius: 2px;
      //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      //     background-color: #363351;
      // }

      // .content::-webkit-scrollbar-track {
      //     border-radius: 2px;
      //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      //     background-color: #363351;
      // }

    .btn {
      width: 244px;
      height: 46px;
      text-align: center;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(66, 66, 66, 1);
      font-size: 16px;
      font-weight: 500;
      background: rgba(251, 209, 61, 1);
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
        // border-top: solid 1px rgb(230,230,230);
      //box-shadow: 1px 1px 8px rgba(#ff942e, 0.5);
    }
  }

  .close {
    position: absolute;
    font-size: 34px;
    bottom: -80px;
    left: 50%;
    margin-left: -17px;
  }
}

</style>

